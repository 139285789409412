




































































































































































































import BindPhoneModel from "./index";
export default BindPhoneModel;
